import React from 'react';
import Image from '../../util/Image';
import faq from '../../assets/images/faq/faq.json';

const Faq = () => {
  const { faqImageSrc, faqImageAlt } = faq.faq;
  return (
    <section className="faq">
      <Image className="faq-image" filename={faqImageSrc} alt={faqImageAlt} />
      <h1>Frequently Asked Questions</h1>
    </section>
  );
};

export default Faq;
