import React from 'react';
import Layout from '../layouts/main/Layout';
import Faq from '../components/Faq/Faq';
import NoIndex from '../util/NoIndex/NoIndex';

const faq = ({ ...props }) => {
  const { originalPath } = props.pathContext.intl;
  return (
    <Layout originalPath={originalPath}>
      <NoIndex />
      <Faq />
    </Layout>
  );
};
export default faq;
